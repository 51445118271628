<template>

  <div class="row">
    <div class="col-12 card">
      <div class="row justify-content-space-between top-24">
        <h4 class="col-3 card-title">Добавить обработчик</h4>
        <div class="col-9 align-self-end right">
          <router-link to="/guides"><button class="col-3 btn-secondary">Отменить</button></router-link>
          <button class="col-3 btn-primary"><span class="icon-Plus"></span>Сохранить</button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 top-24">
          <div class="text-field">
            <label class="text-field__label" for="search">Имя справочника</label>
            <div class="text-field__icon">
              <input class="text-field__input" type="search" name="search" id="search" placeholder="Введите имя" value="">
              <div class="text-field__message">Будет отображаться в общем списке справочников</div>
            </div>
          </div>
        </div>
        <div class="col-12 top-24">
          <div class="row group-field-bd">
            <div class="col-4">
              <label class="text-field__label" for="search">Тип поля</label>
              <div class="text-field__icon">
                <!-- <input class="text-field__input" type="search" name="search" id="search" placeholder="Введите имя" value=""> -->
                <div class="select">
                  <select id="standard-select">
                    <option value="Option 1">String</option>
                    <option value="Option 2">Integer</option>
                    <option value="Option 3">Float</option>
                    <option value="Option 4">Boolean</option>
                    <option value="Option 5">Table</option>
                  </select>
                  <span class="focus"></span>
                </div>
              </div>
            </div>
            <div class="col-4">
              <label class="text-field__label" for="search">Название поля</label>
              <div class="text-field__icon">
                <input class="text-field__input" type="search" name="search" id="search" placeholder="Введите название поля" value="">
  
              </div>
            </div>
            <div class="col-4">
              <label class="text-field__label" for="search">Значение по умолчанию</label>
              <div class="text-field__icon">
                <input class="text-field__input" type="search" name="search" id="search" placeholder="Значение поля по умолчанию" value="">
                <div class="text-field__message">Ставится в случае необходимости</div>
              </div>
            </div>
            <button class="col-2 btn-secondary top-24"><span class="icon-Plus"></span>Добавить</button>

          </div>
          <div class="top-24">
            <input type="checkbox" class="custom-checkbox" id="archive" name="happy" value="yes">
            <label for="archive">Отправить в архив</label>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

</script>
